import React from 'react';
import Layout from 'components/Layout';
import Button from "../components/Button";
import Cards from "../components/Cards";
import about from '../assets/images/home/about.svg';
import scrumboard from '../assets/images/home/scrumboard.svg';
import accounting from '../assets/images/home/accounting.svg';
import dailyscrum from '../assets/images/home/dailyscrum.svg';
import timeManagement from '../assets/images/home/time-management.svg';
import customersEmployees from '../assets/images/home/customers-employees.svg';
import heroImg from '../assets/images/home/hero-img.svg'
import {defineMessages, useIntl} from 'react-intl';
import Img from "../components/Img";

const contentHero = defineMessages({
    h1Part1: {
        id: 'Header.H1.Text',
        defaultMessage: 'The intuitive management app for'
    },
    h1Part2: {
        id: 'Header.H1.Text',
        defaultMessage: 'remote & stationary'
    },
    h1Part3: {
        id: 'Header.H1.Text',
        defaultMessage: 'IT companies'
    },
    intro: {
        id: 'Header.Intro.Text',
        defaultMessage: 'Get your whole company to the next level with the next generation of project and development management application.'
    },
});

const headers = defineMessages({
    header2_first: {
        id: 'IndexPage.Header.H2',
        defaultMessage: 'Manage all your projects and employees with the help of one simple application'
    },
    header2_second: {
        id: 'IndexPage.Header.H2',
        defaultMessage: 'scrumboard'
    },
    header2_third: {
        id: 'IndexPage.Header.H2',
        defaultMessage: 'accounting'
    },
    header2_fourth: {
        id: 'IndexPage.Header.H2',
        defaultMessage: 'dailyscrum'
    },
    header2_fifth: {
        id: 'IndexPage.Header.H2',
        defaultMessage: 'time management'
    },
    header2_sixth: {
        id: 'IndexPage.Header.H2',
        defaultMessage: 'customers & employees management'
    },
});

const content = defineMessages({
    content_h2_first: {
        id: 'IndexPage.Content.Text',
        defaultMessage: 'Our application saves your time and energy allowing you to manage your on site or a remote IT team seamlessly and improve whole software development process, guarantees better performance of your team and better client satisfaction.'
    },
    content_h2_second: {
        id: 'IndexPage.Content.Text',
        defaultMessage: 'Scrumship offers user-friendly functionalities for the entire development team.\n' + 'While some other softwares limits you to just a sprint board view, Scrumship lets you quickly access Backlog, Current Sprint Board, Knowledge Base and Releases view so team members can quickly know where things stand.\n' +
            'Scrumship is created with passion for high-performing teams to save time and reduce errors by managing work from a variety of tools in one centralized place.'
    },
    content_h2_third: {
        id: 'IndexPage.Content.Text',
        defaultMessage: 'Easily manage time cards and expenses, quickly turn them into invoices, send them to your clients, and get paid online.\n' +
            'Scrumship automatically pulls the billable time into invoices. Stay on top of billing and make payments easier for clients. Track your organization\'s financial and performance history.'
    },
    content_h2_fourth: {
        id: 'IndexPage.Content.Text',
        defaultMessage: 'Scrumship Daily views are the best way to keep track of employees’ activity and productivity. \n' +
            'Provide your managers with the tools they need to lead their teams effectively even when they work only on a remote basis.\n' +
            'Boost team performance with Scrumship.'
    },
    content_h2_fifth: {
        id: 'IndexPage.Content.Text',
        defaultMessage: 'Scrumship allows you to organize your team daily activities. Let your employees define their working hours, manage their worklog, request a vacation or sick leave, know the availability of their remote co-workers.'
    },
    content_h2_sixth: {
        id: 'IndexPage.Content.Text',
        defaultMessage: 'Scrumship will boost your business with customer relationship management.\n' +
            'HR managers have instant access to all employees’ administrative documents. All of your employee files and reports are in one Scrumship app for better team management.\n' +
            'Store all your contracts securely and instantly identify key dates and obligations.'
    },

});

const buttons = defineMessages({
    content: {
        id: 'IndexPage.Buttons.Text',
        defaultMessage: 'view more'
    }
});

const alt = defineMessages({
    hero: {
        id: 'Alt.Text',
        defaultMessage: 'developer using scrumship project management software'
    },
    about: {
        id: 'Alt.Text',
        defaultMessage: 'manager using project management app'
    },
    scrumboard: {
        id: 'Alt.Text',
        defaultMessage: 'developer showing advantages project management app - Scrumship'
    },
    accounting: {
        id: 'Alt.Text',
        defaultMessage: 'women using Scrumship - project management tool'
    },
    dailyscrum: {
        id: 'Alt.Text',
        defaultMessage: 'people talking about Scrumship'
    },
    timeManagement: {
        id: 'Alt.Text',
        defaultMessage: 'manager showing agile management with Scrumship'
    },
    customersEmployees: {
        id: 'Alt.Text',
        defaultMessage: 'greeting people'
    }
})

const IndexPage = ({location}) => {
    const {formatMessage} = useIntl();
    return (
        <Layout pageName="home" location={location}>
            <section className='hero'>
                <div className='container one-col'>
                    <div className='hero-wrapper'>
                        <div className='hero-content'>
                            <h1><span className='first-line'>{formatMessage(contentHero.h1Part1)}</span>
                                <span className='text-decoration-container'><span
                                    className='text-decoration-home'>{formatMessage(contentHero.h1Part2)}</span></span> {formatMessage(contentHero.h1Part3)}
                            </h1>
                            <p>{formatMessage(contentHero.intro)}</p>
                        </div>
                        <div className='hero-img'>
                            <Img src={heroImg} alt={formatMessage(alt.hero)}/>
                        </div>
                    </div>
                </div>
            </section>
            <div className='spacer'></div>
            <section className='about' id='about'>
                <div className='container two-col'>
                    <div className='content-container'>
                        <div className='title'>
                            <h2>{formatMessage(headers.header2_first)}</h2>
                        </div>
                        <p>{formatMessage(content.content_h2_first)}</p>
                    </div>
                    <div className='image-container'>
                        <Img src={about} alt={formatMessage(alt.about)}/>
                    </div>
                </div>
            </section>
            <section className='special-text'>
                <div className='container one-col'>
                    <div className='text-decoration-container'>
                        <h1 className='text-decoration'>Features</h1>
                    </div>
                </div>
            </section>
            <section className='scrumboard'>
                <div className='container two-col'>
                    <div className='image-container half-width'>
                        <Img src={scrumboard} alt={formatMessage(alt.scrumboard)}/>
                    </div>
                    <div className='content-container half-width'>
                        <div className='title'>
                            <h2>{formatMessage(headers.header2_second)}</h2>
                        </div>
                        <p>{formatMessage(content.content_h2_second)}</p>
                        <Button btnType='btn-primary btn-large' text={formatMessage(buttons.content)}
                                linkTo='scrumboard'/>
                    </div>
                </div>
            </section>
            <section className='accounting'>
                <div className='container two-col accounting'>
                    <div className='content-container half-width'>
                        <div className='title'>
                            <h2>{formatMessage(headers.header2_third)}</h2>
                        </div>
                        <p>{formatMessage(content.content_h2_third)}</p>
                        <Button btnType='btn-primary btn-large' text={formatMessage(buttons.content)}
                                linkTo='accounting'/>
                    </div>
                    <div className='image-container half-width'>
                        <Img src={accounting} alt={formatMessage(alt.accounting)}/>
                    </div>
                </div>
            </section>
            <section className='dailyscrum'>
                <div className='container two-col'>
                    <div className='image-container half-width'>
                        <Img src={dailyscrum} alt={formatMessage(alt.dailyscrum)}/>
                    </div>
                    <div className='content-container half-width'>
                        <div className='title'>
                            <h2>{formatMessage(headers.header2_fourth)}</h2>
                        </div>
                        <p>{formatMessage(content.content_h2_fourth)}</p>
                        <Button btnType='btn-primary btn-large' text={formatMessage(buttons.content)}
                                linkTo='dailyscrum'/>
                    </div>
                </div>
            </section>
            <section className='time-management'>
                <div className='container two-col time-management'>
                    <div className='content-container half-width'>
                        <div className='title'>
                            <h2>{formatMessage(headers.header2_fifth)}</h2>
                        </div>
                        <p>{formatMessage(content.content_h2_fifth)}</p>
                        <Button btnType='btn-primary btn-large' text={formatMessage(buttons.content)}
                                linkTo='time-management'/>
                    </div>
                    <div className='image-container half-width'>
                        <Img src={timeManagement} alt={formatMessage(alt.timeManagement)}/>
                    </div>
                </div>
            </section>
            <section className='customers-employees'>
                <div className='container two-col'>
                    <div className='image-container half-width'>
                        <Img src={customersEmployees} alt={formatMessage(alt.customersEmployees)}/>
                    </div>
                    <div className='content-container half-width'>
                        <div className='title'>
                            <h2>{formatMessage(headers.header2_sixth)}</h2>
                        </div>
                        <p>{formatMessage(content.content_h2_sixth)}</p>
                        <Button btnType='btn-primary btn-large' text={formatMessage(buttons.content)}
                                linkTo='customers-employees'/>
                    </div>
                </div>
            </section>
            <Cards/>
        </Layout>
    );
};

export default IndexPage;
