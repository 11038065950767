import React from 'react';
import Button from "./Button";
import {defineMessages, useIntl} from "react-intl";
import {
    calendar,
    flightHours,
    hotfix,
    jumpOff,
    knowledgeBase,
    recruitmentMode
} from '../assets/images/icons/exportIcons';

const cardArray = [
    {
        id: 0,
        header: 'Jump Off',
        content: 'Breaks during working hours are vital for your team to rest up before returning to effective work. Scrumship records the time your employee has a break from work in order to create a successful and transparent working environment.',
        icon: jumpOff,
        alt: 'cup of coffee icon',
        class: 'yellow',
    },
    {
        id: 1,
        header: 'Calendar',
        content: 'Scrumship calendar visualize your schedule and reminds you of important events, such as holidays and vacation time. Calendar is a useful tool for keeping track of upcoming meetings, deadlines, and milestones.',
        icon: calendar,
        alt: 'calendar icon',
        class: 'green',
    },
    {
        id: 2,
        header: 'Recruitment Mode',
        content: 'Speed up your HR processes with Scrumship. Scrumship helps recruiters do everything from sourcing candidates, setting up interviews, reminders and collecting updated CVs. Free your team from the repetitive manual tasks and endless email threads, so they can focus on goals and hire right candidates.',
        icon: recruitmentMode,
        alt: 'person icon',
        class: 'pink',
    },
    {
        id: 3,
        header: 'Hotfix Mode',
        content: 'Sometimes you’re facing outages and critical bugs and your developers don’t react quickly enough? To not disappoint our clients in Scrumship, in case of such unpleasant situation, we change the state of the whole application so your team can react instantly.',
        icon: hotfix,
        alt: 'fire icon',
        class: 'pink',
    },
    {
        id: 4,
        header: 'Flight Hours',
        content: 'Flight Hours report is representing your employees time spent on specific projects and for specific client in total. Based on the flight hours you can organize your rate board, so you don’t have to worry about raises - let Scrumship do it for you.',
        icon: flightHours,
        alt: 'searching icon',
        class: 'yellow',
    },
    {
        id: 5,
        header: 'Knowledge Base',
        content: 'Scrumship makes it easy for people to find solutions to their problems. You can create a collection of documentation that includes answers to frequently asked questions, how-to guides and troubleshooting instructions.',
        icon: knowledgeBase,
        alt: 'books icon',
        class: 'green',
    },
];

const headers = defineMessages({
    header1: {
        id: 'Cards.Header.H1',
        defaultMessage: 'Other Features'
    },
});

const content = defineMessages({
    content_main: {
        id: 'Cards.ContentMain.Text',
        defaultMessage: 'Here is the brief description of what great features Scrumship gives to your team and your company. That’s not all.'
    },
})

const buttons = defineMessages({
    content: {
        id: 'Cards.Buttons.Text',
        defaultMessage: 'view more'
    }
});

const Cards = () => {
    const {formatMessage} = useIntl();

    const renderCards = (cardArray) => {
        return cardArray.map(item =>
            <div className='card-box' key={item.id}>
                <div className={`card-body ${item.class}`}>
                    <div className='card-icon'><img src={item.icon} alt={item.alt}/></div>
                    <h3 className='card-title'>{item.header}</h3>
                    <p>{item.content}</p>
                </div>
            </div>
        )
    }
    return (
        <section className='cards'>
            <div className='container one-col'>
                <div className='content-container full-width'>
                    <div className='title'>
                        <h2>{formatMessage(headers.header1)}</h2>
                    </div>
                    <p>{formatMessage(content.content_main)}</p>
                </div>
                <div className='cards-wrapper'>
                    {renderCards(cardArray)}
                </div>
                <Button btnType='btn-primary btn-large' text={formatMessage(buttons.content)} linkTo='other-features'/>
            </div>
        </section>
    );
};

export default Cards;
